import { useDispatch, useSelector } from "react-redux";
import { selectConfig } from "../../features/config/configSlice";
import { showSnackbar } from "../../helpers/notification";
import { get } from "../../helpers/api";
import { useEffect } from "react";
import { SearchPlaces } from "../../components/mapScreen/SearchPlaces";
import { MapMap } from "../../components/mapScreen/MapMap";
import {
	MsSetChatRoomDocId,
	MsSetCityHost,
	MsSetCuratedPlaces,
	MsSetHotel,
	MsSetHotelDocId,
	MsSetHotels,
	selectMapScreen,
} from "../../features/mapScreen/mapScreenSlice";
import { panTo, switchMapScreen, toggleConnect } from "../../helpers/mapScreen";
import { SelectedPlace } from "../../components/mapScreen/SelectedPlace";
import { MapScanWristband } from "../../components/mapScreen/MapScanWristband";

import { AnimatePresence, motion } from "framer-motion";
import Loading from "../../components/svg/Loading";
import CityHostSlider from "../../components/mapScreen/CityHostSlider";

export default function HomeCityHostScreen() {
	const dispatch = useDispatch();
	const config = useSelector(selectConfig);
	const mapScreen = useSelector(selectMapScreen);

	/**
	 * Get last login from the mobile api and get the host content
	 */
	useEffect(() => {
		getLastLogin();
		const intervalId = setInterval(getLastLogin, 300000); // 5 minutes
		return () => clearInterval(intervalId);
	}, []);

	/**
	 * Get last login from the mobile api.
	 * The hosts login in the console so we know who is the last host.
	 */
	const getLastLogin = () => {
		get({
			url: `host/current`,
			params: { HotelCode: config.Property?.Abbreviation ?? "" },
		})
			.then((response) => {
				init(response.data.result.id);
			})
			.catch((error) => {
				showSnackbar("Please refresh the page", "error", "top-center");
			});
	};

	/**
	 * Init map,
	 * Get hotels
	 * Get places
	 * Set center to hotel
	 */
	const init = (mobile_api_id: string) => {
		get({ url: `map-screen/init`, params: { mobileApiId: mobile_api_id } })
			.then((response) => {
				if (response.data && response.data.Success) {
					dispatch(MsSetHotel(response.data.Hotel));
					dispatch(MsSetHotels(response.data.Hotels));
					dispatch(MsSetCuratedPlaces(response.data.CuratedPlaces));
					dispatch(MsSetCityHost(response.data.CityHost));
				} else {
					showSnackbar(response.data.Message, "error", "bottom-center");
				}
			})
			.catch((error) => {
				showSnackbar(error.response?.Message, "error", "bottom-center");
			});
	};

	return (
		<div className="fixed inset-0 bg-cityhub-500">
			<div className="flex justify-center w-full h-full">
				{mapScreen.CityHost === null ||
					(mapScreen.CityHost.Slides.length === 0 && (
						<div className="flex justify-center items-center h-screen">
							<Loading className="w-48 h-48 animate-loading text-cityhub-500" />
						</div>
					))}
				{mapScreen.CityHost !== null &&
					mapScreen.CityHost.Slides.length > 0 && (
						<div className="absolute top-0 left-0 right-0 bottom-48 overflow-hidden p-4">
							<CityHostSlider rounded={true} aspect="20/20" />
						</div>
					)}
			</div>

			<MapScanWristband />
		</div>
	);
}
