import { Link, useParams, useSearchParams } from "react-router-dom"
import ArrowSmall from "../components/svg/ArrowSmall"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import {
  clearConfiguration,
  setApiKey,
  setConfiguration,
} from "../features/config/configSlice"
import { get } from "../helpers/api"
import { showSnackbar } from "../helpers/notification"

export default function Index() {
  const dispatch = useDispatch()
  // let { apiKey } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();


  // Set api key
  useEffect(() => {
    if (searchParams.get("apiKey") !== null) {
      dispatch(setApiKey(searchParams.get("apiKey") ?? ''))
      setTimeout(() => {
        refreshConfig()
      }, 500);
    }
  }, [searchParams])

  const refreshConfig = () => {
    
    dispatch(clearConfiguration())
    get({ url: "configuration", params: {} }).then((response) => {
      if (response.data && response.data.Success) {
        showSnackbar(response.data.Message, "success", "top-center")
        dispatch(
          setConfiguration({
            Property: response.data.Property,
            Kiosk: response.data.Kiosk,
          }),
        )
      } else {
        dispatch(setApiKey(""))
        showSnackbar("Incorrect api key", "error", "top-center")
      }
    })
  }
  return (
    <div className="flex flex-col p-12 space-y-2 justify-end items-start h-screen">
      <div className="text-h1">How can we help you?</div>
      <div className="text-h3 text-center">
        <Link to="/kiosk" className="flex items-center space-x-2">
          <span>Go to the kiosk</span> <ArrowSmall />
        </Link>
        <Link to="/mapscreen" className="flex items-center space-x-2">
          <span>Go to the Map screen</span> <ArrowSmall />
        </Link>
        <Link to="/cityhost-screen" className="flex items-center space-x-2">
          <span>Go to the CityHost screen</span> <ArrowSmall />
        </Link>
      </div>
    </div>
  )
}
