import { useSelector } from "react-redux";
import { selectMapScreen } from "../../features/mapScreen/mapScreenSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import { useEffect, useState } from "react";

interface Props {
	rounded?: boolean;
	aspect?: string;
}

export default function CityHostSlider({
	rounded = true,
	aspect = "20/35",
}: Props) {
	const mapScreen = useSelector(selectMapScreen);
	const [show, setShow] = useState<boolean>(true);

	useEffect(() => {
		setShow(mapScreen.State === "CityHost");
	}, [mapScreen.State]);

	return (
		<>
			{show && (
				<div
					className={`w-full aspect-[${aspect}] overflow-hidden ${
						rounded ? "rounded-md" : ""
					} `}
				>
					{mapScreen.CityHost && mapScreen.CityHost?.Slides && (
						<Swiper
							autoplay={{
								delay: 3000,
								disableOnInteraction: true,
							}}
							loop={true}
							modules={[Autoplay, Navigation, Pagination]}
							// onAutoplayTimeLeft={onAutoplayTimeLeft}
							className="mySwiper"
						>
							{mapScreen.CityHost?.Slides.map((slide, i) => (
								<SwiperSlide key={i}>
									<div className="relative w-full h-full">
										{slide.Type === "image" && (
											<img
												src={slide.Url}
												className="w-full h-full object-cover"
											/>
										)}
										{slide.Text && (
											<div className="absolute bottom-12 left-4 right-4 text-h2 text-white bg-gray-800/75 p-2 text-center rounded-md">
												{slide.Text}
											</div>
										)}
									</div>
								</SwiperSlide>
							))}

							<div
								className="absolute top-0 h-12 w-full z-20 p-2 from-black/0 to-black/90"
								slot="container-start"
							>
								{/* <div
									className={`grid grid-cols-3 grid-cols-${mapScreen.CityHost?.Slides.length} gap-2`}
								>
									{mapScreen.CityHost?.Slides.map((slide, i) => (
										<div className="mt-4 bg-gray-400 w-full h-[2px]" key={i}>
											<div
												className="h-full bg-gray-200"
												ref={elementsRef.current[i]}
											></div>
										</div>
									))}
								</div> */}
								<div className="mt-2 ml-2 flex jutify-center items-center space-x-8 text-white">
									{mapScreen.CityHost?.ProfileImage !== "" && (
										<img
											src={mapScreen.CityHost?.ProfileImage}
											className="w-14 h-14 rounded-full object-cover ring ring-white/75 ring-offset-2"
										/>
									)}
									<span className="text-h2 drop-shadow-md">
										CityHost {mapScreen.CityHost?.Name}
									</span>
								</div>
							</div>
						</Swiper>
					)}
				</div>
			)}
		</>
	);
}
